<template>
  <div class="progress-box" v-if="score >= 0 && totalScore">
    <div
      class="corner-box"
      :style="`transform: rotate(${(score / totalScore) * 180}deg);`"
    >
      <div class="corner-box2">
        <span class="score-corner"></span>
      </div>
    </div>
    <div class="score-box">
      <div class="score">
        {{ score }}
      </div>
      <h5 style="font-size:18px" class="text-center">out of {{ totalScore }}</h5>
    </div>
    <div class="circle-progress">
      <div class="border-out">
        <div class="border-in"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "circle-progress",
  props: {
    score: {
      default: 0
    },
    totalScore: {
      default: 120
    }
  },
  methods: {
    getProgress(index) {}
  }
};
</script>

<style scoped lang="scss">
.progress-box {
  position: relative;
  width: 160px;
  height: 80px;
  margin: 0 auto;
}
.circle-progress {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.corner-box {
  position: absolute;
  width: 130px;
  height: 20px;
  left: 15px;
  bottom: -10px;
}
.score-box {
  position: absolute;
  justify-content: space-between;
  width: 160px;
  bottom: -40px;
}
.score-box .score {
  text-align: center;
  font-size: 26px;
  font-weight: 700;
  color: black;
}
.corner-box2 {
  position: relative;
}
.score-corner {
  position: absolute;
  width: 0;
  height: 0;
  border-top: 9px solid transparent;
  border-right: 18px solid black;
  border-bottom: 9px solid transparent;
}
body {
  background: white;
}
.circle-progress .border-out {
  border: 1px solid #ccc;
  width: 160px;
  height: 160px;
  background: #ccc;
  border-radius: 50%;
  background: -webkit-linear-gradient(
    left,
    #8a8a8a,
    #d7d7d7
  ); /* Safari 5.1 - 6.0 */
  background: -o-linear-gradient(
    left,
    #8a8a8a,
    #d7d7d7
  ); /* Opera 11.1 - 12.0 */
  background: -moz-linear-gradient(
    left,
    #8a8a8a,
    #d7d7d7
  ); /* Firefox 3.6 - 15 */
  background: linear-gradient(to right, #8a8a8a, #d7d7d7); /* 标准的语法 */
}
.circle-progress .border-in {
  background: white;
  height: 110px;
  margin: 25px;
  border-radius: 50%;
}
</style>
