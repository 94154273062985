<template>
  <div class="score_border text-left">
    <div class="score_border_head">
      <div>
        <h3>
          <b>
            {{ moment(scoreDate).format("MMMM DD, YYYY") }}<br />
            <span @dblclick="switchScoreAbleMode">Test Date Scores</span>
          </b>
          <el-button
            v-if="updateScoreAble"
            size="mini"
            type="primary"
            @click="updateScore"
          >
            重新批改考券
          </el-button>
        </h3>
      </div>
    </div>
    <div class="score-card-box">
      <div class="score-card-left">
        <h5 class="text-center title-total-score">
          <b>Total Score</b>
        </h5>
        <div style="display:flex;justify-content: center;">
          <div class="score-progress">
            <span>0</span>
            <div>
              <Progress
                :score="total"
                :totalScore="sectionNum === 1 ? 30 : 120"
              />
            </div>
            <span>
              {{ sectionNum === 1 ? 30 : 120 }}
            </span>
          </div>
        </div>
      </div>
      <div class="score-card-right">
        <div
          class="score_section"
          v-for="(section, index) in sectionsArr"
          :key="index"
          v-show="user_score[`${section}_score`]"
        >
          <b class="score-title">
            {{ section.charAt(0).toUpperCase() + section.slice(1) }}:
          </b>
          <template v-if="user_score[`${section}_score`]">
            <b class="score-title score-title-score">
              {{ user_score[`${section}_score`] }}
            </b>
            <div class="score-bar-box">
              <div class="score-bar">
                <span>0</span>
                <div class="score_progress">
                  <div
                    class="corner"
                    :style="{
                      'margin-left': getLeft(user_score[`${section}_score`])
                    }"
                  ></div>
                </div>
                <span>30</span>
              </div>
            </div>
          </template>
          <template v-else>
            <b class="score-title score-title-score">
              -
            </b>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import role from "@/mixins/role.js";
import TOEFL from "@/apis/toefl";
import Progress from "@/views/toefl/transcript/components/Progress.vue";
import moment from "moment";

export default {
  components: {
    Progress
  },

  mixins: [role],

  props: [
    "id",
    "user_score",
    "scoreDate",
    "reading",
    "listening",
    "speaking",
    "writing"
  ],
  data() {
    return {
      total: 0,
      sectionsArr: ["reading", "listening", "speaking", "writing"],
      updateScoreAble: false
    };
  },
  computed: {
    moment() {
      return moment;
    },
    isAdmin() {
      return this.isRoleAdmin();
    },
    sectionNum() {
      let num = 0;
      if (this.reading) {
        num++;
      }
      if (this.listening) {
        num++;
      }
      if (this.speaking) {
        num++;
      }
      if (this.writing) {
        num++;
      }
      return num;
    }
  },
  watch: {
    user_score() {
      let total = 0;
      total =
        this.user_score.reading_score +
        this.user_score.listening_score +
        this.user_score.speaking_score +
        this.user_score.writing_score;
      this.total = total;
    }
  },

  mounted() {
    let total = 0;
    total =
      this.user_score.reading_score +
      this.user_score.listening_score +
      this.user_score.speaking_score +
      this.user_score.writing_score;
    this.total = total;
  },

  methods: {
    getLeft(score) {
      let percent = Math.round((score / 30) * 100);
      return `calc(${percent}% - 9px)`;
    },
    switchScoreAbleMode() {
      if (this.isAdmin) {
        this.updateScoreAble = !this.updateScoreAble;
      }
    },
    async updateScore() {
      await TOEFL.updateScore(this.id);
      this.$router.go(0);
    }
  }
};
</script>

<style scoped>
.score_border_head h3{
  font-size: 1.5rem;
}
.score-card-box {
  display: flex;
  background: #fff;
}
.score-card-left {
  margin: 0 10px;
}
.score-card-right {
  min-height: 320px;
  flex: 1;
  margin: 0 10px;
  margin-top: 10px;
}
.score_section {
  display: flex;
  height: 80px;
}
.score-title {
  display: inline-block;
  font-size: 20px;
  width: 90px;
  line-height: 80px;
}
.score-title-score {
  width: 25px;
  margin: 0 10px;
  font-size: 22px;
}
.score-bar-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
}
.score-bar {
  display: flex;
}

.score-bar span {
  font-size: 12px;
  display: flex;
  flex-direction: column-reverse;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: -4px;
}
.score-bar .score_progress {
  flex: 1;
  margin: 0 4px;
}
.score-progress {
  display: flex;
}
.score-progress span {
  display: flex;
  margin: 0 2px;
  flex-direction: column-reverse;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: -8px;
}
.score_progress .corner {
  margin-left: 9px;
}

.score_border_head h4,
.score_border_head h6 {
  color: #fff;
}
h5 {
  font-size: 18px;
  line-height: 150%;
  color: black;
}
.title-total-score {
  font-size: 20px;
  margin: 15px 0 0 0;
}
.updateScore {
  display: none;
  font-size: 24px;
}
.progress_total:hover .updateScore {
  display: inline-block;
}

.progress_total:hover .total {
  display: none;
}
.score-point {
  height: 10px;
  position: absolute;
  top: -10px;
}

@media screen and (max-width: 768px) {
  .score-card-box {
    display: block;
  }
  .score-card-left,
  .score-card-right {
    margin: 0;
    width: 100%;
    padding: 0 15px;
    height: 150px;
  }
  .score_section h5 {
    display: inline;
    margin: 0 20px 0 0;
  }
  .score_border_head {
    padding: 15px;
  }
  h5 {
    margin: 10px 0;
  }
}
</style>
