<template>
  <div class="score_border text-left">
    <div class="score_border_head">
      <div>
        <h4 style="margin: 0">
          <b><em>MyBest</em>&#8482; Scores</b>
        </h4>
        <h6>
          Your highest section scores from all valid test dates,
          <br />as of {{ moment().format("MMMM DD, YYYY") }}.
        </h6>
      </div>
    </div>
    <div class="score-card-box" v-if="this.total > 0">
      <div class="score-card-left">
        <h5 class="text-center title-total-score" style="line-height: 1">
          <b>Sum of Highest Section Scores</b>
        </h5>
        <div>
          <div>
            <div class="text-center progress_total">{{ total }}</div>
          </div>
          <h5 class="text-center">out of 120</h5>
        </div>
      </div>
      <div class="score-card-right">
        <div
          class="score_section"
          v-for="(section, index) in sectionsArr"
          :key="index"
        >
          <template v-if="user_score[`max_${section}`][`max_${section}_score`]">
            <div class="score-title-box">
              <b class="score-title">
                {{ section.charAt(0).toUpperCase() + section.slice(1) }}:
              </b>
              <router-link
                :to="{
                  name: 'toeflResult',
                  query: { id: user_score[`max_${section}`].id }
                }"
              >
                Test Date:<br />
                {{ getMoment(user_score[`max_${section}`].created_at) }}
              </router-link>
            </div>
            <b class="score-title-score">{{
              user_score[`max_${section}`][`max_${section}_score`]
            }}</b>
            <div class="score-bar-box">
              <div class="score-bar">
                <span>0</span>
                <div class="score_progress">
                  <div
                    class="corner"
                    :style="{
                      'margin-left': getLeft(
                        user_score[`max_${section}`][`max_${section}_score`]
                      )
                    }"
                  ></div>
                </div>
                <span>30</span>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="score-title-box">
              <b class="score-title">
                {{ section.charAt(0).toUpperCase() + section.slice(1) }}:
              </b>
            </div>
            <b class="score-title-score"> - </b>
          </template>
        </div>
      </div>
    </div>
    <div v-else class="complete-test">
      <h4>
        Your MyBest&#8482; Scores will appear here once you complete a complete
        test.
      </h4>
      <router-link
        :to="{
          name: 'ToeflTestResults',
          query: { type: 'full_mock', page: 1 }
        }"
      >
        Do a complete test.
      </router-link>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import moment from "moment";

export default {
  components: {},

  mixins: [],

  props: ["user_score", "isPDF"],
  data() {
    return {
      total: 0,
      sectionsArr: ["reading", "listening", "speaking", "writing"]
    };
  },
  computed: {
    moment() {
      return moment;
    },
    isPhone() {
      let isPhone = 120;
      if (document.body.clientWidth <= 768) {
        isPhone = 220;
      }
      return isPhone;
    }
  },
  watch: {
    user_score() {
      let total = 0;
      total =
        this.user_score.max_reading.max_reading_score +
        this.user_score.max_listening.max_listening_score +
        this.user_score.max_speaking.max_speaking_score +
        this.user_score.max_writing.max_writing_score;
      console.log(total);
      this.total = total;
    }
  },

  mounted() {
    let total = 0;
    total =
      this.user_score.max_reading.max_reading_score +
      this.user_score.max_listening.max_listening_score +
      this.user_score.max_speaking.max_speaking_score +
      this.user_score.max_writing.max_writing_score;
    console.log(total);
    this.total = total;
  },

  methods: {
    getLeft(score) {
      let percent = Math.round((score / 30) * 100);
      return `calc(${percent}% - 9px)`;
    },
    getMoment(date) {
      return moment(date).format("MMM DD, YYYY");
    }
  }
};
</script>

<style scoped>
.progress_total {
  margin: 30px auto 5px;
  border-color: #ccc;
  color: black;
  width: 80px;
  height: 64px;
  line-height: 54px;
}
.score-card-box {
  display: flex;
  background: #fff;
}
.score-card-left {
  width: 160px;
  margin: 0 10px;
  margin-left: 20px;
}
.score-card-right {
  min-height: 320px;
  flex: 1;
  margin: 0 10px;
  margin-top: 10px;
}
.score_section {
  display: flex;
  height: 80px;
}
.title-total-score {
  margin: 10px 0 0 0;
}
.score-title-box {
  width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.score-title-box a {
  line-height: 1;
}
.score-title {
  display: inline-block;
  font-size: 20px;
}
.score-title-score {
  width: 25px;
  margin: 0 20px 0 5px;
  font-size: 22px;
  line-height: 80px;
}
.score-bar-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
}
.score-bar span {
  font-size: 12px;
  display: flex;
  flex-direction: column-reverse;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: -4px;
}
.score-bar {
  display: flex;
}
.score-bar .score_progress {
  flex: 1;
  margin: 0 4px;
}
.score_border_head h4,
.score_border_head h6 {
  color: black;
}
h5 {
  font-size: 18px;
  line-height: 150%;
  color: black;
}
.complete-test {
  padding: 50px;
  min-height: 333px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.score-point {
  margin-top: 20px;
  height: 10px;
}
.score_section {
  min-height: 70px;
}
.score_section a {
  color: #212529 !important;
  font-weight: 500;
}
@media screen and (max-width: 768px) {
  .score-card-box {
    display: block;
  }
  .score-card-left,
  .score-card-right {
    margin: 0;
    width: 100%;
    padding: 0 15px;
  }
  .score_border_head {
    height: auto;
    min-height: 110px;
  }
  .score_section h5 {
    display: inline;
    margin: 0 10px 0 0;
  }
  .question-tips {
    display: none;
  }
  .score_border_head {
    padding: 15px;
  }
  h5 {
    margin: 10px 0;
  }
}
</style>
