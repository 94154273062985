var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"score_border text-left"},[_c('div',{staticClass:"score_border_head"},[_c('div',[_vm._m(0),_c('h6',[_vm._v(" Your highest section scores from all valid test dates, "),_c('br'),_vm._v("as of "+_vm._s(_vm.moment().format("MMMM DD, YYYY"))+". ")])])]),(this.total > 0)?_c('div',{staticClass:"score-card-box"},[_c('div',{staticClass:"score-card-left"},[_vm._m(1),_c('div',[_c('div',[_c('div',{staticClass:"text-center progress_total"},[_vm._v(_vm._s(_vm.total))])]),_c('h5',{staticClass:"text-center"},[_vm._v("out of 120")])])]),_c('div',{staticClass:"score-card-right"},_vm._l((_vm.sectionsArr),function(section,index){return _c('div',{key:index,staticClass:"score_section"},[(_vm.user_score[("max_" + section)][("max_" + section + "_score")])?[_c('div',{staticClass:"score-title-box"},[_c('b',{staticClass:"score-title"},[_vm._v(" "+_vm._s(section.charAt(0).toUpperCase() + section.slice(1))+": ")]),_c('router-link',{attrs:{"to":{
                name: 'toeflResult',
                query: { id: _vm.user_score[("max_" + section)].id }
              }}},[_vm._v(" Test Date:"),_c('br'),_vm._v(" "+_vm._s(_vm.getMoment(_vm.user_score[("max_" + section)].created_at))+" ")])],1),_c('b',{staticClass:"score-title-score"},[_vm._v(_vm._s(_vm.user_score[("max_" + section)][("max_" + section + "_score")]))]),_c('div',{staticClass:"score-bar-box"},[_c('div',{staticClass:"score-bar"},[_c('span',[_vm._v("0")]),_c('div',{staticClass:"score_progress"},[_c('div',{staticClass:"corner",style:({
                    'margin-left': _vm.getLeft(
                      _vm.user_score[("max_" + section)][("max_" + section + "_score")]
                    )
                  })})]),_c('span',[_vm._v("30")])])])]:[_c('div',{staticClass:"score-title-box"},[_c('b',{staticClass:"score-title"},[_vm._v(" "+_vm._s(section.charAt(0).toUpperCase() + section.slice(1))+": ")])]),_c('b',{staticClass:"score-title-score"},[_vm._v(" - ")])]],2)}),0)]):_c('div',{staticClass:"complete-test"},[_c('h4',[_vm._v(" Your MyBest™ Scores will appear here once you complete a complete test. ")]),_c('router-link',{attrs:{"to":{
        name: 'ToeflTestResults',
        query: { type: 'full_mock', page: 1 }
      }}},[_vm._v(" Do a complete test. ")])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h4',{staticStyle:{"margin":"0"}},[_c('b',[_c('em',[_vm._v("MyBest")]),_vm._v("™ Scores")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h5',{staticClass:"text-center title-total-score",staticStyle:{"line-height":"1"}},[_c('b',[_vm._v("Sum of Highest Section Scores")])])}]

export { render, staticRenderFns }